
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SummaryService from '../services/summary-service'
  @Component({
    name: 'BargeWidget',
    components: {}
  })
export default class BargeWidget extends Vue {
    protected isTableBusy = false
    protected barges: any = []

    protected fields: any = [{
      key: 'barge_name',
      label: 'Barge',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'no_of_trips',
      label: 'No. of Trips',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'trip_number',
      label: 'Trip No.',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'quantities',
      label: 'Quantities',
      thStyle: {
        width: '14%'
      }
    }
    /* {
      key: 'date_of_arrival',
      label: 'Date of Arrival',
      thStyle: {
        width: '7%'
      }
    },
    {
      key: 'time_of_arrival',
      label: 'Time of Arrival',
      thStyle: {
        width: '7%'
      }
    } */
    ]

    public show (job) {
      this.populateBargeDetails(job)
    }

    protected async populateBargeDetails (job: any) {
      this.isTableBusy = true
      const response = await SummaryService.getBargeDetails(job.id)
      this.isTableBusy = false
      this.barges = response.data
    }

    protected cancel () {
      this.barges = []
    }
}

