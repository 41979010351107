
import {
  Component,
  Vue
} from 'vue-property-decorator'
import FormService from '../../services/form-service'
import RepositoryService from '../../services/repository-service'
import EmployeeService from '@/views/employee/services/employee-service'
import {
  JobModule
} from '../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  ErrorModule
} from '@/store/modules/ErrorModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import UomJobs from '@/data/uom-jobs.json'
import DateHelper from '@/mixins/date-mixins'
import Draggable from 'vuedraggable'
  @Component({
    name: 'JobForm',
    components: {
      Draggable
    }
  })
export default class JobForm extends Vue {
    protected isBusy = false
    protected isEditForm = false
    protected isDrag = false
    protected showComponent = false
    protected lines: any = []
    protected inquiryNumbers: any = []
    protected clients: any = []
    protected uoms = UomJobs
    protected runningNumber: any = null
    protected ports: any = []
    protected portTerminals: any = []
    public services: any = []
    protected companies: any = []
    protected officersInCharge: any = []
    protected types = ['Own Job', 'Other']
    protected categories: any = []

    $v: Vuelidate

    @Validations()
    validations = {
      header: {
        client_id: {
          required
        },
        job_number: {
          required
        },
        company: {
          required
        },
        type: {
          required
        },
        vessel_name: {
          minLength: minLength(3),
          maxLength: maxLength(150)
        },
        voyage_number: {
          minLength: minLength(3),
          maxLength: maxLength(20)
        },
        port: {
          required
        },
        terminal: {
          required
        },
        contact_person: {
          required
        },
        contact_person_number: {
          minLength: minLength(7),
          maxLength: maxLength(10),
          required
        },
        officer_in_charge: {
          required
        }
      }
    }

    protected header: any = {
      id: null,
      inquiry_number: null,
      job_number: null,
      reference_number: null,
      port: {},
      terminal: {},
      client_id: null,
      contact_person: null,
      contact_person_number: null,
      type: null,
      vessel_name: null,
      voyage_number: null,
      vessel_arrival_date: null,
      company: {},
      officer_in_charge: {},
      status: null,
      created_user: null,
      modified_user: null
    }

    public show (job: any) {
      this.showComponent = true

      if (Object.keys(job).length !== 0) {
        this.isEditForm = true
        this.populateJobDetail(job)
      } else {
        this.populateRunningNumber()
        // this.setJobRefNumber()
        this.addLine()
      }

      this.populateInquiryNumbers()
      this.populatePorts()
      this.populateClients()
      this.populateCompanyNames()
      this.populateOfficersInCharge()
      this.populateCategories()
    }

    protected hide () {
      this.cancel()
      this.showComponent = false
    }

    protected async populateRunningNumber () {
      const response = await FormService.getRunningNumber()
      const number = response.data
      if (number.toString().length === 1) this.runningNumber = '00' + number
      else if (number.toString().length === 2) this.runningNumber = '0' + number
      else if (number.toString().length === 3) this.runningNumber = number
      else if (number.toString().length === 4) this.runningNumber = number
    }

    protected async setJobRefNumber () {
      const date = DateHelper.yearMonth()
      const runningNumber = await FormService.getRefRunningNumber()
      this.header.reference_number = `${date}/${runningNumber.data}`
    }

    protected async populateInquiryNumbers () {
      const response = await FormService.getInquiryNumbers()
      this.inquiryNumbers = response.data
    }

    protected async populateInquiry (code: any) {
      const response = await FormService.getInquiry(code)
      for (var key in response.data.header) this.header[key] = response.data.header[key]
      this.lines = response.data.lines.map(lines => ({
        ...lines,
        isEdit: false
      }))
    }

    protected async populatePorts () {
      const response = await FormService.getPortNames()
      response.data.forEach((element) => {
        this.ports.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    protected async populatePortTerminals (port) {
      this.portTerminals = []
      const response = await FormService.getPortTerminals(port)
      response.data.forEach((element) => {
        this.portTerminals.push({
          value: {
            id: element.id,
            name: element.code
          },
          text: element.code + ' - ' + element.name
        })
      })
    }

    protected async populateClients () {
      const response = await RepositoryService.getClients()
      this.clients = response.data
    }

    protected populateCategories () {
      this.categories = []
      FormService.getCategories().then((response) => {
        response.data.forEach((element) => {
          this.categories.push({
            value: element.id,
            text: element.name
          })
        })
      })
    }

    protected populateServices (index: number, categoryId: any) {
      const category = this.categories.find(category => category.value === categoryId)
      this.lines[index].category_name = category.text

      this.lines[index].services = []
      this.lines[index].service = {}
      this.lines[index].description = null
      this.lines[index].estimated_volume = null
      this.lines[index].unit_of_measurement = null

      this.getServices(categoryId).then((response) => {
        response.data.forEach((service) => {
          this.lines[index].services.push({
            value: {
              id: service.id,
              name: service.name,
              description: service.description,
              code: service.code
            },
            text: service.name
          })
        })
      })
    }

    protected getServices (categoryId) {
      return FormService.getServices(categoryId)
    }

    protected async populateJobDetail (job: any) {
      const response = await FormService.getJobDetail(job.id)
      const headerValues = Object.keys(this.header)
      Object.keys(response.data).filter(key => headerValues.includes(key))
        .forEach(key => {
          this.header[key] = response.data[key]
          this.header.company = {
            id: response.data.company_id,
            name: response.data.company_name
          }
          this.header.port = {
            id: response.data.port_id,
            name: response.data.port_name
          }
          this.header.officer_in_charge = {
            id: response.data.officer_in_charge_id,
            name: response.data.officer_in_charge_name
          }
          this.header.terminal = {
            id: response.data.terminal_id,
            name: response.data.terminal_name
          }
          delete response.data[key]
        })
      this.populatePortTerminals(this.header.port)
      response.data.lines.forEach(async (line) => {
        const services = await this.getServices(line.category_id)
        this.lines.push({
          isEdit: false,
          id: line.id,
          category_id: line.category_id,
          category_name: line.category_name,
          services: services.data.map(service => ({ value: { id: service.id, name: service.name, description: service.description, code: service.code }, text: service.name })),
          service: { id: line.service_id, name: line.service_type, description: line.service_description, code: line.service_code },
          description: line.service_description,
          unit_of_measurement: line.unit_of_measurement,
          estimated_volume: line.estimated_volume
        })
      })
    }

    protected async populateOfficersInCharge () {
      const response = await EmployeeService.getCOfficersInCharge()
      response.data.forEach((element) => {
        this.officersInCharge.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    protected async populateCompanyNames () {
      const response = await FormService.getCompanyNames()
      response.data.forEach((element) => {
        this.companies.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    protected setJobNumber () {
      this.header.job_number = null
      const date = DateHelper.twoDigitYearMonth()
      const client = this.clients.find(client => client.id === this.header.client_id)
      const clientCode = client.code
      const serviceCodes = this.lines.map((line) => {
        return line.service.code
      }).join('')
      this.header.job_number = `${date}/${clientCode}/${serviceCodes}/${this.runningNumber}`
    }

    protected async setService (line: any) {
      line.description = line.service.description
    }

    public setClientId (client: any) {
      this.header.client_id = client.id
    }

    protected addLine () {
      this.lines = this.lines.map(lines => ({
        ...lines,
        isEdit: false
      }))
      const lineNumber = this.lines.length
      this.lines.push({
        isEdit: true,
        id: null,
        line_number: lineNumber + 1,
        category_id: null,
        category_name: null,
        service: {},
        services: [],
        description: null,
        unit_of_measurement: null,
        estimated_volume: null,
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      })
    }

    protected editLine (index: any) {
      this.lines[index].isEdit = !this.lines[index].isEdit
    }

    protected deleteLine (index: number, line: any) {
      const msg = `Are you sure you want to delete line ${index + 1}?`
      if (confirm(msg)) {
        if (line.id !== null) {
          FormService.deleteJobLine(this.header.id, line.id)
            .then(response => {
              ToastModule.message(response.data.message)
              this.lines.splice(index, 1)
              this.save()
            })
            .catch(error => ToastModule.message(error.response.data.message))
        } else this.lines.splice(index, 1)
      }
    }

    protected saveAndNew () {
      this.save()
      this.cancel()
    }

    protected saveAndClose () {
      this.save()
      this.cancel()
    }

    protected async save () {
      // if (!this.isEditForm) this.setJobNumber()
      if (!this.isValidated()) return false
      const header: any = {}
      const lines: any = []
      Object.keys(this.header).forEach(key => {
        header[key] = this.header[key]
        header.company_id = this.header.company.id
        header.company_name = this.header.company.name
        header.port_id = this.header.port.id
        header.port_name = this.header.port.name
        header.terminal_id = this.header.terminal.id
        header.terminal_name = this.header.terminal.name
        header.officer_in_charge_id = this.header.officer_in_charge.id
        header.officer_in_charge_name = this.header.officer_in_charge.name
        header.status = 'pending'
        header.created_user = AuthModule.user.fullname
        header.modified_user = AuthModule.user.fullname
        delete header.port
        delete header.terminal
      })
      this.lines.forEach((line, index) => {
        lines.push({
          line_number: index + 1,
          category_id: line.category_id,
          category_name: line.category_name,
          service_id: line.service.id,
          service_type: line.service.name,
          service_code: line.service.code,
          service_description: line.description,
          unit_of_measurement: line.unit_of_measurement,
          estimated_volume: line.estimated_volume,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })

      if (this.isEditForm) this.update(header, lines)
      else this.create(header, lines)
    }

    protected async create (header: any, lines: any) {
      FormService.create(header, lines).then((response) => {
        ToastModule.message(response.data.message)
        this.isEditForm = true
      }).catch(error => {
        ErrorModule.set(error.response.data)
        ToastModule.message(error.response.data.message)
      })
    }

    protected update (header: any, lines: any) {
      FormService.update(header, lines).then((response) => {
        ToastModule.message(response.data.message)
        JobModule.setJob(response.data.job.id)
      }).catch(error => {
        ErrorModule.set(error.response.data)
        ToastModule.message(error.response.data.message)
      })
    }

    protected isValidated () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      const lineCount = this.lines.filter(line => Object.keys(line.service).length === 0).length
      if (lineCount > 0) {
        NotifyModule.set('It appears that the job lines are empty, Please check..')
        return false
      }

      return true
    }

    protected cancel () {
      this.reset(this.header)
      this.lines = []
      this.categories = []
      this.services = []
      this.companies = []
      this.clients = []
      this.ports = []
      this.portTerminals = []
      this.officersInCharge = []
      this.isEditForm = false
      this.$nextTick(() => {
        this.$v.header.$reset()
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

