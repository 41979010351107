
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import BoardService from '../../services/board-service'
import {
  JobModule
} from '../../store/JobModule'
import DateHelper from '@/mixins/date-mixins'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  utils,
  writeFile
} from 'xlsx'
import {
  Printd
} from 'printd'
import Status from '@/components/Status/index.vue'
import JobForm from '../Form/Form.vue'
import JobOperation from '../Operation/Layout/Index.vue'
import JobSummary from '../Summary/index.vue'
import JobCost from '../Operation/Body/Costing/JobCost.vue'
  @Component({
    name: 'JobBoard',
    components: {
      Status,
      JobForm,
      JobOperation,
      JobCost,
      JobSummary
    }
  })
export default class JobBoard extends Vue {
    protected isBusy = false
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected jobs: any = []
    protected status: any = null
    protected statuses: any = ['pending', 'commenced', 'attend', 'loaded', 'dispatch', 'received', 'completed']
    protected searchQuery = null
    // protected searchString = null
    protected isTableChecked = false
    protected styles = ['https://api.smc-spl-application.com/print.css']
    protected document: any = null

    protected fields = [{
      key: 'is_checked',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center custom-table-field'
    },
    {
      key: 'created_date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'job_number',
      label: 'Job Number',
      sortable: true,
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel_name',
      label: 'Vessel',
      sortable: true,
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'vessel_arrival_date',
      label: 'Arrival Date',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'voyage_number',
      label: 'Voyage',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'port_name',
      label: 'Port/Terminal',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'lines',
      label: 'Services',
      thStyle: {
        width: '12%'
      },
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '12%'
      },
      class: 'text-center'
    }
    ]

    $v: Vuelidate

    @Validations()
    validations

    get date () {
      return DateHelper.today('dd/mm/yyyy')
    }

    get permissions () {
      return AuthModule.permissions
    }

    get rows () {
      return this.jobs.length
    }

    get isLineChecked () {
      const count = this.jobs.filter(job => job.is_checked).length
      if (count !== 0) return true
      return false
    }

    @Watch('isTableChecked', {
      deep: true
    })
    setIsChecked () {
      if (this.isTableChecked) {
        this.jobs.forEach(job => {
          job.is_checked = true
        })
      } else {
        this.jobs.forEach(job => {
          job.is_checked = false
        })
      }
    }

    get selectedJobs () {
      return this.jobs.filter(job => job.is_checked === true)
    }

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateJobs()
      this.document = new Printd()
    }

    protected showComponent (type: string, item: any = {}) {
      switch (type) {
        case 'vehicles':
          (this.$refs.Vehicles as any).show()
          break

        case 'employees':
          (this.$refs.Employees as any).show()
          break

        case 'ports':
          (this.$refs.PortsAndTerminals as any).show()
          break

        case 'form':
          (this.$refs.JobForm as any).show(item)
          break

        case 'operation':
          JobModule.setJob(item);
          (this.$refs.JobOperation as any).show()
          break

        case 'costing':
          (this.$refs.JobCost as any).show(item)
          break

        case 'summary':
          (this.$refs.JobSummary as any).show(item)
          break
      }
    }

    protected async populateJobs () {
      this.jobs = []
      this.isBusy = true
      const response = await BoardService.getOfficeJobs()
      this.jobs = response.data.map(job => ({
        ...job,
        is_checked: false
      }))
      this.isBusy = false
    }

    protected async sortByArrivalDate () {
      this.jobs = this.jobs.sort((a, b) => Date.parse(b.vessel_arrival_date.split('/').reverse().join('-')) - Date
        .parse(a.vessel_arrival_date.split('/').reverse().join('-'))).map(job => ({
        ...job,
        is_checked: false
      }))
    }

    protected async sortByCreatedDate () {
      this.jobs = this.jobs.sort((a, b) => Date.parse(b.created_date.split('/').reverse().join('-')) - Date.parse(a
        .created_date.split('/').reverse().join('-'))).map(job => ({
        ...job,
        is_checked: false
      }))
    }

    /* protected async setStatusString () {
      this.searchString = null
      this.searchString = this.status
    } */

    protected async populateFilteredOfficeJobs (status) {
      if (status === null) this.populateJobs()
      else {
        this.jobs = []
        this.isBusy = true
        const response = await BoardService.getFilteredOfficeJobs(status)
        this.jobs = response.data.map(job => ({
          ...job,
          is_checked: false
        }))
        this.isBusy = false
      }
    }

    /* protected setQueryString () {
      this.searchString = null
      this.searchString = this.searchQuery
    } */

    protected printJobs () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('printJobs'), this.styles)
      })
    }

    protected downloadJobs () {
      const jobDetails = this.selectedJobs.map(job => ({
        Date: job.created_date,
        'Job Number': job.job_number,
        Vessel: job.vessel_name,
        'Arrival Date': job.vessel_arrival_date,
        Voyage: job.voyage_number,
        'Port/Terminal': job.port_name + '/' + job.terminal_name,
        Services: job.lines.map(function (line) {
          return line.service_type + ' - ' + line.estimated_volume + ' ' + line.unit_of_measurement
        }).join(', '),
        Status: job.status
      }))

      const ws = utils.json_to_sheet(jobDetails)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Data')
      writeFile(wb, 'Jobs Details.xlsx')
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

