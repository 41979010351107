import APIService from '@/services/api-service'
const resource = '/job/cost'

class JobCostService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobCostLines (jobId: any) {
    return this.connector.get(`${resource}/lines/${jobId}`)
  }

  public getJobCostDetails (jobId: any) {
    return this.connector.get(`${resource}/details/${jobId}`)
  }

  public getJobInventoryDetails (jobId: any) {
    return this.connector.get(`${resource}/inventory-details/${jobId}`)
  }

  public createJobCostLine (line: any) {
    return this.connector.put(`${resource}/line/create`, { line: line })
  }

  public updateJobCostLine (line: any) {
    return this.connector.put(`${resource}/line/update`, { line: line })
  }

  public deleteJobCostLine (line: any) {
    return this.connector.post(`${resource}/line/delete`, { line: line })
  }

  public getEstimatedBowserCount (jobId: any) {
    return this.connector.get(`${resource}/estimated-bowser-count/${jobId}`)
  }

  public getActualBowserCount (jobId: any) {
    return this.connector.get(`${resource}/actual-bowser-count/${jobId}`)
  }

  public getSpecificCostLines () {
    return this.connector.get(`${resource}/specific-cost-lines`)
  }

  public createJobCost (estimation: any) {
    return this.connector.post(`${resource}/create`, { estimation: estimation })
  }

  public calculateActualJobCost (data: any) {
    return this.connector.put(`${resource}/update`, data)
  }
}

export default new JobCostService()
