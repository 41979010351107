
import JobCostService from '../../../../services/job-cost-service'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import Status from '@/components/Status/index.vue'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  Component,
  Vue
} from 'vue-property-decorator'
  @Component({
    name: 'JobCost',
    components: {
      VueAutonumeric,
      Status
    }
  })
export default class JobCost extends Vue {
    protected isTableBusy = false
    protected showComponent = false
    protected actualBowserCount: any = 0
    protected specifics: any = []
    protected estimation: any = {
      bowser_count: 0,
      quantity: 0
    }

    protected lines: any = []
    protected line: any = {}
    protected job: any = {}
    protected inventory: any = {
      oil_quantity: 0,
      water_quantity: 0,
      garbage_quantity: 0,
      other_quantity: 0
    }

    protected actual: any = {
      id: null,
      total_estimated_cost: 0,
      estimated_exchange_rate: null,
      actual_exchange_rate: null
    }

    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected fields = [{
      key: '#',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'description',
      thStyle: {
        width: '15%'
      },
      class: 'text-left'
    },
    {
      key: 'amount',
      label: 'Estimated Amount',
      thStyle: {
        width: '8%'
      },
      class: 'text-left'
    },
    {
      key: 'actual_rate',
      label: 'Actual Rate',
      thStyle: {
        width: '8%'
      },
      class: 'text-left'
    },
    {
      key: 'actual_quantity',
      label: 'Actual Quantity',
      thStyle: {
        width: '8%'
      },
      class: 'text-left'
    },
    {
      key: 'actual_amount',
      label: 'Actual Amount',
      thStyle: {
        width: '8%'
      },
      class: 'text-left'
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '5%'
      },
      class: 'text-center'
    }
    ]

    $v: Vuelidate

    @Validations()
    validations = {
      actual: {
        estimated_exchange_rate: {
          required
        },
        actual_exchange_rate: {
          required
        }
      }
    }

    get ActualCost () {
      let amount = 0
      return this.lines.reduce((total, line) => {
        amount = (total + parseFloat(((line.actual_amount === null ? 0 : line.actual_amount).toString()).replace(
          /,(?=\d{3})/g,
          '')))
        this.actual.total_actual_cost = amount
        return amount
      }, 0)
    }

    get EstimatedCost () {
      let amount = 0
      return this.lines.reduce((total, line) => {
        amount = (total + parseFloat(((line.amount === null ? 0 : line.amount).toString()).replace(
          /,(?=\d{3})/g,
          '')))
        this.actual.total_estimated_cost = amount
        return amount
      }, 0)
    }

    public show (job: any) {
      this.job = job
      this.populateJobCostDetails(this.job.id)
      this.estimatedBowserCount(this.job.id)
      this.populateActualBowserCount(this.job.id)
      this.populateJobInventoryDetails(this.job.id)
      this.populateSpecificCostLines()
      this.showComponent = true
    }

    public hide () {
      this.cancel()
      this.showComponent = false
    }

    protected saveJobCostLine (tableIndex, line) {
      if (this.lines[tableIndex].isEdit) this.createJobCostLine(line)
      else this.updateJobCostLine(line)
    }

    protected createJobCostLine (line) {
      this.line = line
      this.line.job_cost_id = this.actual.id
      this.line.total_estimated_cost = this.actual.total_estimated_cost
      this.line.total_actual_cost = this.actual.total_actual_cost
      this.line.modified_user = AuthModule.user.fullname
      JobCostService.createJobCostLine(this.line).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobCostDetails(this.job.id)
      }).catch(error => {
        console.log(error)
      })
    }

    protected updateJobCostLine (line) {
      this.line = line
      this.line.total_actual_cost = this.actual.total_actual_cost
      this.line.modified_user = AuthModule.user.fullname
      JobCostService.updateJobCostLine(this.line).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobCostDetails(this.job.id)
      }).catch(error => {
        console.log(error)
      })
    }

    protected populateJobCostDetails (jobId) {
      this.isTableBusy = true
      JobCostService.getJobCostDetails(jobId).then((response) => {
        this.isTableBusy = false
        if (response.data === '') {
          this.lines = []
          this.actual = {
            id: null,
            estimated_exchange_rate: null,
            actual_exchange_rate: null
          }
        } else {
          this.lines = response.data.lines
          this.actual = response.data
        }
      })
    }

    protected populateJobInventoryDetails (jobId) {
      JobCostService.getJobInventoryDetails(jobId).then((response) => {
        if (response.data === '') {
          this.inventory = {
            oil_quantity: 0,
            water_quantity: 0,
            garbage_quantity: 0,
            other_quantity: 0
          }
        } else {
          this.inventory = response.data
        }
      })
    }

    protected createJobCost () {
      this.$v.actual.estimated_exchange_rate.$touch()
      if (this.$v.actual.estimated_exchange_rate.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      if (this.actual.id !== null) return NotifyModule.set('Estimated Job Cost already calculated')

      this.estimation.job_id = this.job.id
      this.estimation.estimated_exchange_rate = this.actual.estimated_exchange_rate
      this.estimation.created_user = AuthModule.user.fullname
      JobCostService.createJobCost(this.estimation).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobCostDetails(this.job.id)
      }).catch((error) => {
        NotifyModule.set('There was a problem saving your data, Please check and retry again')
        console.log(error)
      })
    }

    protected calculateActualJobCost () {
      this.$v.actual.actual_exchange_rate.$touch()
      if (this.$v.actual.actual_exchange_rate.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      const job: any = {
        id: this.job.id,
        oil_quantity: this.inventory.oil_quantity,
        actual_bowser_count: this.actualBowserCount,
        actual_exchange_rate: this.actual.actual_exchange_rate,
        modified_user: AuthModule.user.fullname
      }

      JobCostService.calculateActualJobCost(job).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobCostDetails(this.job.id)
      }).catch(error => {
        NotifyModule.set('There was a problem saving your data, Please check and retry again')
        console.log(error)
      })
    }

    protected async estimatedBowserCount (jobId) {
      const response = await JobCostService.getEstimatedBowserCount(jobId)
      this.estimation.bowser_count = response.data.bowser_count
      this.estimation.quantity = response.data.quantity
    }

    protected async populateActualBowserCount (jobId) {
      const response = await JobCostService.getActualBowserCount(jobId)
      this.actualBowserCount = response.data
    }

    protected calculateLineTotal (tableIndex) {
      if (this.lines[tableIndex].unit_of_measurement === 'quantity' && this.lines[tableIndex].currency === 'USD') {
        this.lines[tableIndex].actual_amount = this.lines[tableIndex].actual_rate * this.actual.actual_exchange_rate *
          this.lines[tableIndex].actual_quantity
      } else {
        this.lines[tableIndex].actual_amount = this.lines[tableIndex].actual_rate * this.lines[tableIndex]
          .actual_quantity
      }
    }

    protected setSpecificLineDetails (specific, tableIndex) {
      this.lines[tableIndex].cost_id = specific.costId
      this.lines[tableIndex].description = specific.description
      this.lines[tableIndex].unit_of_measurement = specific.uom
      this.lines[tableIndex].rate = specific.rate
      this.lines[tableIndex].currency = specific.currency
      /* let qty: any = 0
      let amount: any = 0
      switch (specific.uom) {
        case 'bowser':
          qty = this.estimation.bowser_count
          amount = qty * specific.rate
          break
        case 'person':
          qty = this.estimation.bowser_count + 1
          amount = qty * specific.rate
          break
        case 'job':
          qty = 1
          amount = qty * specific.rate
          break
        case 'quantity':
          qty = this.estimation.quantity
          if (specific.currency === 'USD') amount = qty * specific.rate * this.actual.estimated_exchange_rate
          else amount = qty * specific.rate
      }
      this.lines[tableIndex].amount = amount
      this.lines[tableIndex].quantity = qty */
    }

    protected deleteJobCostLine (index, line) {
      const msg = `Are you sure you want to delete line ${index + 1}?`
      if (confirm(msg)) {
        if (line.id !== null) {
          this.line = line
          this.line.total_estimated_cost = this.actual.total_estimated_cost
          this.line.total_actual_cost = this.actual.total_actual_cost
          this.line.modified_user = AuthModule.user.fullname
          JobCostService.deleteJobCostLine(this.line).then(response => {
            ToastModule.message(response.data.message)
            this.lines.splice(index, 1)
          }).catch(error => ToastModule.message(error.response.data.message))
        } else this.lines.splice(index, 1)
      }
    }

    protected addLine () {
      this.lines = this.lines.map(lines => ({
        ...lines,
        isEdit: false
      }))
      this.lines.push({
        isEdit: true,
        id: null,
        description: null,
        amount: 0,
        actual_rate: 0,
        actual_quantity: 0,
        actual_amount: 0,
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      })
    }

    protected populateSpecificCostLines () {
      JobCostService.getSpecificCostLines().then((response) => {
        response.data.forEach((element) => {
          this.specifics.push({
            value: {
              costId: element.id,
              description: element.description,
              currency: element.currency,
              uom: element.unit_of_measurement,
              quantity: element.quantity,
              rate: element.rate
            },
            text: element.description
          })
        })
      })
    }

    public cancel () {
      this.lines = []
      this.specifics = []
      this.actualBowserCount = 0
      this.reset(this.estimation)
      this.reset(this.inventory)
      this.reset(this.actual)
      this.$nextTick(() => {
        this.$v.actual.$reset()
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

