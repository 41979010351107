
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SummaryService from '../services/summary-service'
import Status from '@/components/Status/index.vue'
  @Component({
    name: 'DispatchWidget',
    components: {
      Status
    }
  })
export default class DispatchWidget extends Vue {
    protected isTableBusy = false
    protected notes: any = []

    protected fields: any = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-right'
    },
    {
      key: 'vehicle_type',
      label: 'Type',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'content_type',
      label: 'Content Type',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'tonnage',
      label: 'Tonnage',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'location_name',
      label: 'Location',
      thStyle: {
        width: '10%'
      }
    }
    ]

    public show (job) {
      this.populateDispatchDetail(job)
    }

    protected async populateDispatchDetail (job: any) {
      this.isTableBusy = true
      const response = await SummaryService.getDispatchDetails(job.id)
      this.isTableBusy = false
      this.notes = response.data.map(note => ({
        ...note,
        tonnage: note.lines.reduce((total, line) => { return total + parseFloat(line.tonnage) }, 0)
      }))
    }

    protected cancel () {
      this.notes = []
    }
}

