
import {
  Component,
  Vue
} from 'vue-property-decorator'
import Status from '@/components/Status/index.vue'
import QuantityWidget from '../../widget/Quantity.vue'
import DetailsWidget from '../../widget/Details.vue'
import BargeWidget from '../../widget/Barge.vue'
import DispatchWidget from '../../widget/Dispatch.vue'
  @Component({
    name: 'Summary',
    components: {
      Status,
      QuantityWidget,
      DetailsWidget,
      BargeWidget,
      DispatchWidget
    }
  })
export default class Summary extends Vue {
    protected showComponent = false
    protected jobNumber: any = null

    public show (job) {
      this.showComponent = true
      this.jobNumber = job.job_number;
      (this.$refs.QuantityWidget as any).show(job);
      (this.$refs.DetailsWidget as any).show(job);
      (this.$refs.BargeWidget as any).show(job);
      (this.$refs.DispatchWidget as any).show(job)
    }

    protected hide () {
      this.showComponent = false
      this.jobNumber = null;
      (this.$refs.QuantityWidget as any).cancel();
      (this.$refs.DetailsWidget as any).cancel();
      (this.$refs.BargeWidget as any).cancel();
      (this.$refs.DispatchWidget as any).cancel()
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

